<template>
    <section>
        <van-popup :show="show"
                   position="top"
                   round
                   overlay-class="overlay-smooth"
                   :lock-scroll="false" z-index="20" @close="emit('close')">
            <div style="max-width: 600px; margin:5rem auto 1rem;">
                <VanCellGroup :border="false">
                    <VanCell :title="promo?.schema?.accountsTitle||promo?.title||t('Moje punkty w programie')"
                             title-class="flex-grow" center>
                        <template #title>
                            <Markdown :content="promo?.schema?.accountsTitle||promo?.title||t('Moje punkty w programie')"></Markdown>
                        </template>
                        <template #extra>
                            <van-popover v-if="eligiblePromos.length>1" theme="dark"
                                         placement="left-start"
                                         :actions="_map(eligiblePromos,p=>({text:p.title,id:p.id}))"
                                         @select="(p)=>(selectedPromo = p.id)">
                                <template #reference>
                                    <VanIcon name="more-o" size="26" color="var(--van-primary-color)"></VanIcon>
                                </template>
                            </van-popover>
                        </template>
                    </VanCell>
                    <PromoAccountSummary :promo="promo" :key="promo?.id"></PromoAccountSummary>
                </VanCellGroup>
                <div style="display: flex;justify-content: space-between;gap:10px;margin:1rem 1rem 1.5rem;">
                    <VanButton size="small" round
                               type="primary"
                               plain style="padding: 0 12px;" @click="navigateTo('/receipts');emit('close')">
                        {{ t('moje zakupy') }}
                    </VanButton>
                    <VanButton size="small" round
                               type="primary"
                               style="padding: 0 12px 0 10px;" icon="plus" @click="onRegister();emit('close')">
                        {{ t('dodaj nowy paragon') }}
                    </VanButton>
                </div>
                <VanCell :title="awardsMessage" center
                         style="background-color: var(--van-background-3);margin:0 0 -1rem 0;padding:1rem;">
                    <template #extra>
                        <VanButton size="small"
                                   round
                                   plain style="padding: 0 12px;" @click="navigateTo('/awards');emit('close')">
                            {{ t('pokaż moje nagrody') }}
                        </VanButton>
                    </template>
                </VanCell>
            </div>
        </van-popup>
    </section>
</template>

<script setup>
import RegisterReceipt from "~/components/receipts/registerReceipt.vue";
const props = defineProps({
    show: Boolean
})
const emit = defineEmits(['close'])
const store = useStore()
const route = useRoute()
const params = computed(()=>route.params)
const eligibleTypes = ['program','competition','lottery','quiz','game']
const eligiblePromos = computed(()=>_filter(store.promos,p=> {
    return _includes(eligibleTypes, p.type)
            && (p.endDate > new Date())
            && p.purchaseAware
            && p.active && (!p.testing || member?.profile?.testUser)
}))
const promo = computed(()=>{
    return _find(eligiblePromos.value,{id:selectedPromo.value||params.value?.promo}) ||
            _find(eligiblePromos.value,{id:store.program?.id})
})

const selectedPromo = ref()


const large = computed(()=>{
    return _includes(['','/',],route.path)
})

const onRegister = ()=>{
    useMount(RegisterReceipt,{props:{promoId:promo.value?.id||null}})
}
watch(()=>props.show,(val,old)=>{
    if(val && !old){
        selectedPromo.value = null
    }
})
const {member} = useMember()
const awardsToCollect = computed(()=>{
    return !!_filter(member?.profile?.awards,a=>!a.delivered)?.length
})
const awardsMessage = computed(()=>{
    return awardsToCollect.value ? t('Masz nagrody do odbioru') : t('Nie masz nagród do odbioru')
})

const {t} = useLocale('topBarAction',{
    en:{
        'dodaj paragon':'my new receipt',
        'masz ${points} pkt':'I have ${points} p.',
        'Moje punkty w programie':"My loyalty points",
        'Aktualnie dostępne':'Currently available',
        'Oczekujące na weryfikację':'Awaiting verification',
        'moje zakupy':'my purchases',
        'dodaj nowy paragon':'add new receipt',
        'pkt':'pts.',
        'pokaż moje nagrody':'show my prizes',
        'Masz nagrody do odbioru':'You have prizes to collect',
        'Nie masz nagród do odbioru':'You don\'t have prizes to collect'
    },
    uk: {
        'dodaj paragon':'нова квитанція',
        'masz ${points} pkt':'у вас ${points} p.',
        'Moje punkty w programie':"Мої пункти в програмі",
        'Aktualnie dostępne':'На даний момент доступні',
        'Oczekujące na weryfikację':'Очікує на верифікацію',
        'moje zakupy':'мої квитанції',
        'dodaj nowy paragon':'додати нову квитанцію'
    }

})
</script>

<style scoped>
.notice-swipe {
    height: 30px;
    line-height: 30px;
}
.button-action {
    margin: 0 10px;
    font-weight: 600;
    min-width:32px;
    transition: width 0.3s ease-in-out;
    width: 110px;
    padding: 0 11px;
}
.button-action.small {
    width: 32px;
}
.icon-plus {
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
}
.icon-plus.rotate {
    transform: rotate(45deg);
}
.title-class {
    color: #888;
}
.van-popover__action {
    width: unset !important;

}
</style>